.panel {
  border-width: 0;
  box-shadow: none;
}

.panel-footer {
  border-top-width: 0;
  text-align:center;

  //maximize available space for centered buttons
  @include isaac-respond-max-width(xs) {
    padding-left:0;
    padding-right:0;
  }

  @include isaac-respond-min-width(sm) {
    text-align:right;
  }
}

.panel-title {
  font-size:24px;
}


.panel-group {
  .panel {

    + .panel {
      margin-top: 10px;
    }

    a[data-toggle="collapse"] {
      text-decoration: none;

      //plus/minus icon styling only, no positioning
      &:after {
        font-family: 'icomoon';
        content: "\e619";
        color:$brand-primary;
        font-size:21px;
      }

      &:hover {
        &:after {
          color: $brand-tertiary;
        }
      }
    }

    &.collapsed a[data-toggle="collapse"] {
      &:after {
        content: "\e61a";
      }
    }

    .panel-heading {
      padding-top:0;
      padding-right:0;
      padding-bottom:0;

      .panel-title > a[data-toggle="collapse"] {
        position:relative;
        display:block;
        //maximize click area, right padding also creates space for icon
        padding-top: 11px;
        padding-bottom:21px;

        @include isaac-respond-min-width(sm) {
          padding-top: 31px;
          padding-right:60px;
          padding-bottom:27px;
        }

        text-decoration:none;
        color:$text-color;
        font-family:$font-family-base;
        font-size:16px;
        font-weight:bold;
        line-height:1.6em;


        //position the plus/minus icons in the header (not shown on xs)
        &:after {
          position:absolute;
          right:27px;
          top:31px;
          @include isaac-respond-width(xs) {
            display:none;
          }
        }

      }

    }

    .panel-footer  {
      background-color: #f2f2f2;
      border-radius: $border-radius-large;
      padding:0;

      > a[data-toggle="collapse"] {
        height:35px;
        width:100%;
        display:block;
        padding-top:2px;
      }


    }

  }

}

.panel-homepage {
  margin:20px 0;
  position:relative;

  @include isaac-respond-width(sm) {
    height:300px;
  }

  @include isaac-respond-min-width(md) {
    margin-bottom:20px;
  }
  .panel-heading {
    border-width:0;
  }
  .panel-body {
    @include isaac-respond-width(sm) {
      height:168px;
    }

    //equal height by adding a height
    @include isaac-respond-min-width(md) {
      height: 120px;
    }
  }
  .panel-footer {

  }

  .panel-image {
    height:178px;
    background-color:$brand-secondary;
    border-radius: $border-radius-large;
    background-position:center center;
    background-repeat: no-repeat;
    background-size:auto 50%;

    @include isaac-respond-min-width(sm) {
      background-size:auto 35%;
    }

    @include isaac-respond-min-width(md) {
      height:240px;
      background-size:auto auto;
    }
  }


  @include isaac-respond-width(sm) {
    .panel-heading, .panel-body, .panel-footer, .panel-image {
      width:50%;
    }

    .panel-image {
      height:300px;
      position:absolute;
      top:0;
      right:0;
    }

    //image left
    &.panel-homepage-middle {
      .panel-heading, .panel-body, .panel-footer {
        margin-left:50%;
      }
      .panel-image {
        right:auto;
        left:0;
      }
    }

  }
}

.panel-calculator-result {
  background: #fff;
  @include isaac-respond-min-width(sm) {
    padding:4px;
    border-radius:3px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.2);
    -moz-box-shadow:    0px 0px 5px 0px rgba(50, 50, 50, 0.2);
    box-shadow:         0px 0px 5px 0px rgba(50, 50, 50, 0.2);
  }
}

.panel-loan {
  > .panel-heading {
    padding:10px 0;
    position:relative;
    @include clearfix;
    h1, h2, h3, h4, h5, h6 {
      margin:0;
      padding:0 0 10px 0;
      display:block;
      float:left;
      border-bottom:1px solid $brand-spotcolor-dark;
      width:100%;
    }

    a {
      display:inline-block;
      float:right;
      padding:10px 0 0;
      cursor: pointer;
    }
  }
  > .panel-body {
    padding:10px 0;

    .row {
      padding-bottom:10px;
    }

  }
  > .panel-footer {
    margin-bottom:20px;
    background-color: transparent;
    padding:10px 0;
    a {
      cursor: pointer;
    }
  }

}

.panel-standout {
  background-color:#fff;
  color:$text-color;
  margin:0;
  .panel-heading {
    border-bottom-color:$brand-primary;
    .panel-title {
      color:$brand-primary;
    }
  }
  .panel-body {
    @include clearfix();
    strong {
      color:$brand-primary;
    }
    .panel-body-text {
      @include isaac-respond-min-width(sm) {
        width:calc(100% - 150px);
        float:left;
      }
    }
    .panel-body-btn {
      margin-top:20px;
      text-align: center;
      @include isaac-respond-min-width(sm) {
        text-align: left;
        width:calc(100% - 150px);
        float:left;
      }
    }
    .panel-body-img {
      text-align: center;
      @include isaac-respond-min-width(sm) {
        text-align: right;
        width:150px;
        float:right;
      }
    }
  }

}

.panel-rating {
  background-color:$brand-spotcolor;
  border-radius: 3px;
  padding:15px 0;
  text-align: center;
  margin-top:30px;


  @include isaac-respond-min-width(sm) {
    margin-top:0;
    height:185px;
    overflow: hidden;
  }

  > h1, > h2, > h3, > h4, > h5, > h6 {
    margin-top:0;
  }



}


//two or three equal height panels with sticky footer
@include isaac-respond-min-width(md) {
  $panel-footer-height : 70px;

  .panel-row {
    position: relative;
    margin-left: - $grid-gutter-width / 2;
    margin-right: - $grid-gutter-width / 2;
    @include clearfix();
    margin-bottom: $line-height-computed; //default margin-bottom from .panel

    > .panel {
      margin-left: $grid-gutter-width / 2;
      margin-right: $grid-gutter-width / 2;
      margin-bottom: 0;
      min-height: 1px;
      position: static; //position should be static, so the :before will be positioned relative to he .panel-row, which has position:relative
      float: left;

      //strip the default styling from the panel, since the styling will be appiled on the :before
      border-color: transparent;
      background-color: transparent;
      padding-bottom: $panel-footer-height;

      &:before {
        content: '';
        position: absolute;
        //make it 100% hight relative to the .panel-row
        top: 0;
        bottom: 0;
      }

      .panel-body, .panel-heading {
        //position:relative is needed to make these appear above the :before
        position: relative;
      }

      .panel-footer {
        position: absolute;
        bottom: 0;
        height: $panel-footer-height;
        overflow: hidden;
      }

    }

    //columns count settings
    .panel-col-12 {
      &, &:before, & .panel-footer {
        width: calc(50% - #{$grid-gutter-width});
      }
    }

    .panel-col-8 {
      &, &:before, & .panel-footer {
        width: calc(33.3333% - #{$grid-gutter-width});
      }
    }

    //styling
    > .panel-default:before {
      border-color: $panel-default-border;
      background-color: $panel-bg;
      border-radius: $panel-border-radius;
    }

  }
}

.panel-faq {
  //"equal height" with jumbotron-autoplan

  .panel-body {
    height:207px;
    li {
      padding-bottom: 20px;

      > a {
        color:inherit;
      }

    }
  }

  //fix faq panel on md view, since the header and button text is too wide
  @include isaac-respond-width(md) {
    .panel-footer {
      padding-left: 0;
      padding-right: 0;
      text-align: center;
    }
  }
}

.panel-faq-category {
  .panel-heading {
    border-bottom:1px solid $brand-primary;
  }


  .panel-body {
    padding: 0;

    > ul {
      list-style: none;
      padding:0;
      > li {
        position:relative;
        a {
          padding:10px 25px 10px 20px;
          display: block;
          text-decoration: none;
          border-bottom: 1px solid #e2e2e2;
          color:inherit;

          &:hover {
            color: #fff;
            background-color: $brand-primary;
            &:after {
              color: #fff;
            }
          }

          &:after {
            font-family: 'icomoon';
            content: "\e60c"; //angle-right
            color:$brand-primary;
            position: absolute;
            top:10px;
            right:15px;
          }
        }
      }
    }
  }

  .panel-footer {
    text-align:left;
    padding-left:20px;
    > a {
      font-weight: bold;
      position:relative;
      padding-left:14px;
      &:before {
        @include caret($caret-width-base, $brand-primary, right);
        top:7px;
        left:0;
      }
    }
  }
}