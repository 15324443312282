#accordion{
  overflow: hidden;
  width:100%;
  border-bottom: 1px solid #1B3564 ;
}


#accordion .row {

  @include isaac-respond-min-width(sm) {
    .full{
        width:100%;
    }
    .half{
      width:50%;
    }
    .quarter{
      width:25%;
    }
    .third{
      width:33.3333%
    }
    .sixth{
      width:16.6666%
    }
  }


}



#accordion .row select.half{width:50% !important;}

#accordion .row .fieldDivider{
  color: #999999;
  display: inline-block;
  font-size: 9px;
  height: 24px;
  line-height: 40px;
  margin: 0;
  min-width: 0;
  padding: 0;
  text-align: center;
  width: 5px;
}

#accordion #accept{
  border:0;
}

.ibanLookup{
  margin:10px;
}

#accordion .header{
  border-top:1px solid #e5e5e5;
  padding: 20px 0 0 10px;
}


#accordion .row {
  position:relative;
  div[class^="col-"], div[class*=" col-"] {
    position:relative;
    padding-right:30px; //reserve space for error icon
  }
}

#accordion .customCheckbox{
  border: 0 !important;
}

#accordion .row .asterix{
  color:#999999;
}

#accordion .invisible {
  display: none;
}

#accordion .row input .invisible {
  display: none;
}

#accordion .row.dateField {
  
  .customDateField {
    display: inline-block;
    
    &.day,
    &.month{
      width: 50px!important;
      margin: 6px 0px 0 0;
    }

    &.year {
      width: 70px!important;
      margin-top: 6px;
    }

  }

}

#accordion .row.postalCodeDiv {
  .postalCodeNumbers {
    display: inline-block;
    margin: 6px 0px 0 0;
    width: 70px!important;
  }

  .postalCodeChars {
    display: inline-block;
    margin-top: 6px;
    width: 50px!important;
  }
}


#accordion .row .checkbox {
  display: block;
  float: left;
  width: 15px;
  border:0;
}

#accordion .row .radio{
  margin-top:5px;
  min-height:0;
}

.ie9 #accordion .row .radio {
  margin-top:-2px;
}

#accordion .row .partnerHeader,
#accordion .row .borrowerHeader {
  margin-top: 0px;
  @extend .h3;
}

#accordion .autocompleteWidget {
  overflow: visible;
  position: relative;
}

#submitbuttonDiv {
  text-align: center;
  position:relative;
  padding-top:30px;
  padding-bottom:30px;
  .btn {
    margin-left:10px;
    margin-right:10px;
  }
}

#submitbuttonDiv .loading{
  left: 445px;
  top: 44px;
}

#submitbuttonDiv .button.sent {
  @extend .disabled;
}


#accordion .accordionpage{
  background: none;
}

#accordion .barOverlay {
  background-color: #ecf8fc;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px
}

#accordion h1.ui-state-active{
  cursor:default;
}

#accordion h1.ui-accordion-header{
  padding-left:37px;
  font-size: 20px;
  line-height: 23px;
  width: 100%;
  margin-bottom:0;
}

#accordion #errorDiv {
  height:auto;
}

#errorMessage,
.errordescription{
  background-color:#fff;
  border:1px solid red;
  padding: 5px 30px 10px;
  width:640px;
  color:red;
  background: url("img/erroricon.png") no-repeat 7px 7px;
  margin-bottom:10px;
}

.errordescription{
  width: 612px;
  display:block;
}

#errorMessage p{
  margin:0;
  padding:0;
}

.errorpage{
  padding-top:20px;
}


input.readonly {
  background-color: #EEE;
}

//used by postalcode lookup
.loading{
  background: url(img/loading.gif) no-repeat;
  display: block;
  height: 15px;
  width: 15px;
  position:absolute;
  top:6px;
  right:5px;
}

.loadingbar{
  background: url(img/loadergif.gif) no-repeat;
  display: block;
  height: 27px;
  margin: 5px auto;
  width: 80px;
}

/* -------- Uitzonderingen --------   */

#accordion #TermsOfServiceDiv input{
  width:auto;
}

#accordion #MarketingFlagDiv input{
  width:auto;
}
#accordion #BorrowHeaderDiv,
#accordion #BorrowerFinancialHeaderDiv{
  border-top:0;
  margin-top:5px;
  padding-top:0;
}

#accordion .accept{
  margin-left: 5px !important;
  @include clearfix;
}

#accordion .accept a,
#accordion a.link{
  text-decoration:underline;
  color: #203A6D;
}

#accordion .accept a:hover,
#accordion a.link:hover{
  text-decoration:none;
}

#accordion .accept input[type="checkbox"]{
  display: block;
  float:left;
}

#accordion .accept label{
  padding-left:5px;
  display: block;
  float:left;
  width:95%;
}

/* -------- Jquery UI aanpassingen -------- */

#accordion .ui-widget-content {
  background:none;
  background-color:#fff;
}

#accordion .ui-autocomplete {
  width:145px;
  position : absolute;
  left: 0;
  top: 20px;
  margin:0;
  margin-top: 4px;
  padding:0;
}

.ie8 #accordion .ui-autocomplete,
.ie7 #accordion .ui-autocomplete {
  width:146px;
}

#accordion .ui-menu-item{
  list-style:none;
  margin:0;
  padding:0;
}

#accordion .ui-menu-item a {
  cursor: pointer;
  display: block;
  padding: 5px;
  text-align: left;
  width: auto;
}

#accordion .ui-menu-item {
  width:auto;
  font-weight:normal !important;
  background-color:#fff;
  border:0;
  color:#000;
}

#accordion .ui-menu-item .ui-state-hover{
  width:auto;
  background:none;
  background-color:#e5e5e5;
  border:0;
  color:#000;
  font-weight:normal !important;
}

#accordion .ui-accordion-content{
  padding:15px;
  margin-bottom: 0px;
}

#accordion .ui-accordion-header {
  background:none;
  background-color: $brand-primary;
  color: #FFFFFF;
  cursor: pointer;
  height: 32px;
  margin-top: 1px;
  padding:10px 0 0 10px;
  position: relative;
  border: 0;
}

#accordion .ui-corner-all,
#accordion .ui-corner-top,
#accordion .ui-corner-right,
#accordion .ui-corner-tr {
  -moz-border-radius-topright: 0px;
  -webkit-border-top-right-radius: 0px;
  -khtml-border-top-right-radius: 0px;
  border-top-right-radius: 0px;
}

#accordion .ui-corner-all,
#accordion .ui-corner-top,
#accordion .ui-corner-left,
#accordion .ui-corner-tl {
  -moz-border-radius-topleft: 0px;
  -webkit-border-top-left-radius: 0px;
  -khtml-border-top-left-radius: 0px;
  border-top-left-radius: 0px;
}

#accordion .ui-corner-all,
#accordion .ui-corner-bottom,
#accordion .ui-corner-right,
#accordion .ui-corner-br {
  -moz-border-radius-bottomright: 0px;
  -webkit-border-bottom-right-radius: 0px;
  -khtml-border-bottom-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

#accordion .ui-corner-all,
#accordion .ui-corner-bottom,
#accordion .ui-corner-left,
#accordion .ui-corner-bl {
  -moz-border-radius-bottomleft: 0px;
  -webkit-border-bottom-left-radius: 0px;
  -khtml-border-bottom-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

#accordion .ui-widget-content {
  border:1px solid #ACC6D1 !important;
  border-top:0 !important;
  z-index:99 !important;
}

#accordion .ui-autocomplete{
  top: 23px;
}

.ie7 #accordion .ui-autocomplete{
  top: 23px;
}

#accordion .ui-icon {
  position: absolute;
  left: .5em;
  top: 50%;
  margin-top: -8px;
}

#accordion .ui-icon-triangle-1-s{
  background:url(img/accordionsprite.png) no-repeat left -22px;
  height:20px;
  width:22px;
}

#accordion .ui-icon-triangle-1-e{
  background:url(img/accordionsprite.png) no-repeat left -42px;
  height:20px;
  width:22px;
}

#accordion .ui-accordion-header .ui-icon{
  left:11px;
}


/*validation icons*/
.validicon,
.error.tipsy {
  position: absolute;
  top: 2px;
  right: 0px;
}

.error.tipsy {
  display: block;
  position:absolute;
  width:23px;
  height:30px;
  text-indent:-200px;
  overflow:hidden;
  &:before {
    position:absolute;
    display:block;
    right:-180px;
  }
}

.validicon{
  right:-1px;
}



.control-label {
  position:relative;
  padding-right:25px;
  .tipsy-tooltip {
    position: absolute;
    right: 1px;
    top: 1px;
    @include isaac-respond-min-width(sm) {
      top: 9px;
    }
  }

}


a .dotEditListBox {
  z-index:5;
}


.accordioncontent{
  margin-top:20px;
}


/* popup status aanvraag */

.popupbodyclass{
  background:none !important;
}

#popup h2{
  margin-top: 0;
}

//iban lookup
.convertIban{
  background:#eee;
  padding:15px;
  margin:20px 0;
  .table {
    margin-bottom:0;
  }
  @include isaac-respond-max-width(xs) {
    .btn {
      width:100%;
    }
  }
  .ibanlookup_error{
    color:red;
  }
}
#iban-copy {
  @include isaac-respond-max-width(xs) {
    .btn {
      width:100%;
    }
  }
}

//is either a div or a select, shown aboven the accordion
select[name="isaac_calc_maandLasten"] {
  @extend .form-control;
}
div[name="isaac_calc_maandLasten"] {
  @extend .form-control;
  background-color: #EEE;
  width:50%;
  cursor:$cursor-disabled;
}

//save for later overlaY
.sfl-overlay-close,
.sfl-overlay-save {
  display:inline-block;
  padding-top:12px;
}