.strip-quicklinks {
  background-color:$brand-primary;
  padding-top:13px;
  padding-bottom:43px;
  margin: 20px 0 30px 0;
  color:#fff;

  h2 {
    color:#fff;
  }

  a {
    @include clearfix;
    display:block;
    text-decoration: none;
    padding-top:14px;
    text-align: center;
    @include isaac-respond-min-width(md) {
      text-align: left;
    }

    > i {
      @extend .icon-circle-md;
      background-color:#fff;
      color:$brand-primary;
      @include isaac-respond-min-width(md) {
        float: left;
        width: 80px;
        height: 80px;
        font-size:40px;
        padding-top:20px;
      }
    }

    > div {
      padding:7px 0;
      color:#fff;

      @include isaac-respond-min-width(md) {
        float:left;
        width:calc(100% - 80px);
        padding:7px 0 0 24px;
      }

      > h4 {
        margin:0;
      }

      > strong {
        font-size: 25px;
        font-family: $font-family-webfont;
        font-weight: normal;

        .icon-angle-right {
          position:relative;
          top:2px;
        }
      }
    }
  }
}





