@charset "utf-8";

@import "variables";

//ISAAC mixins
@import "mixins/isaac-responsive";
@import "mixins/isaac-caret";

//combine all stylesheets and generate output
//@import "nonresponsive"; //makes the site non responsive. Make sure the meta viewport is also removed from the head
@import "bootstrap";
@import "font-awesome"; //replaced Bootstrap icons with Font awesome
@import "grid";
@import "isaac_offcanvas";
@import "header";
@import "footer";
@import "navs";
@import "type";
@import "icons";
@import "buttons";
@import "forms";
@import "jumbotron";
@import "tables";
@import "panels";
@import "slider";
@import "calculators";
@import "rating";
@import "tooltip";
@import "accordion";
@import "jquery.fancybox";
@import "pageheader";
@import "advice";
@import "alerts";
@import "sections";
@import "standout";
@import "strip-quicklinks";
@import "pagination";

