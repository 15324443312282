.jumbotron {
  background-color: $brand-primary;
  margin-bottom:20px;

  .jumbotron-img {
    background-position: center;
    background-repeat: no-repeat;
    height:150px;
    background-size: auto 100px;

    @include isaac-respond-min-width(md) {
      height:400px;
      background-size: 450px auto;
    }

    @include isaac-respond-min-width(lg) {
      background-size: auto;
    }
  }

  .jumbotron-body-wrapper {
    display:table;
    width:100%;
    padding-bottom:25px;

    @include isaac-respond-min-width(md) {
      height:400px;
      padding-bottom:0;
    }

    .jumbotron-body {
      display:table-cell;
      vertical-align: middle;
      text-align: center;
      color:#fff;
      .jumbotron-heading {
        color:#fff;
        display:block;

        font-size:30px;
        margin-top:0;
        @include isaac-respond-min-width(md) {
          font-size:36px;
          margin-top:22px;
        }
      }

      a:not(.btn) {
        color:inherit;
        text-decoration: underline;
        &:hover, &:active {
          text-decoration: none;
        }
      }

      .btn {
        margin-top:20px;
      }
    }

  }

  //color variants
  &.jumbotron-hilite {
    background-color: $brand-secondary;
    .jumbotron-body-wrapper {
      .jumbotron-body {
        color:$brand-primary;
        font-size: 16px;
        .jumbotron-heading {
          color:$brand-primary;
        }
        .btn-white {
          border-bottom:1px solid rgba(0,0,0,0.4);
          border-right:1px solid rgba(0,0,0,0.4);
        }
      }

    }
  }

}

.jumbotron-autoplan {
  padding:50px 20px 50px 20px;
  margin-bottom:22px;
  background:$brand-tertiary url(/global/css/img/car-repeat.png) center center;
  border-radius: $border-radius-large;
  text-align: center;

  h1, h2, h3, h4, h5, h6 {
    font-size:24px;
    margin:0 0 40px 0;
    color:$brand-secondary;
  }

  @include isaac-respond-min-width(sm) {
    padding:40px 80px 35px 80px;
    h1, h2, h3, h4, h5, h6 {
      font-size: 48px;
    }
  }

  @include isaac-respond-min-width(sm) {
    padding:80px 60px 75px 60px;
  }

}

.jumbotron-small {

    .jumbotron-img {
      height: 66px;
      background-size: 90px auto;

      @include isaac-respond-min-width(md) {
        height:200px;
        background-size: 250px auto;
      }

      @include isaac-respond-min-width(lg) {
        background-size: 250px auto;
      }
    }

.jumbotron-body-wrapper {
    display:table;
    width:100%;
    height: 66px;
    padding-bottom:15px;

    .jumbotron-body .jumbotron-heading {
      font-size:18px;
      margin-bottom:0;
    }

    @include isaac-respond-min-width(md) {
      height:200px;
      padding-bottom:0;
      .jumbotron-body .jumbotron-heading {
        font-size:30px;
        margin-top:0;
      }
    }



  }
}