.calc_error_main {
  position: absolute;
  background-color: $alert-danger-bg;
  color:$alert-danger-text;
  max-width: 300px;
  padding:10px 15px;
  border: 1px solid red;
  z-index:999;
  border-radius:$alert-border-radius;

  @include isaac-respond-min-width(sm) {

  }
}

#bespaarcal {
  position:relative;
}