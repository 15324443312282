.table {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding-top:4px;
        padding-bottom:4px;
      }
    }
  }
  // Bottom align for column headings
  > thead > tr > th {
    vertical-align: top;
    border-bottom: 0;
  }
  > tbody + tbody {
    border-top: 0;
  }

}


.table-striped,
.table-striped-alt
{
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border-top: 0;
      }
    }
  }
  // Bottom align for column headings
  > thead > tr > th {
    vertical-align: top;
    border-bottom: 0;
  }
  > tbody + tbody {
    border-top: 0;
  }

}

.panel-default .table-striped {
  > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }
}

//alternative striping
.table-striped-alt {
  > thead > tr {
    >td, th {
      background-color: $table-bg-accent;
      .panel-default & {
        background-color: #fff;
      }
    }
  }
  > tbody > tr:nth-child(even) {
    background-color: $table-bg-accent;
    .panel-default & {
      background-color: #fff;
    }
  }
}

//table with calculator results
.table-calculator {
  margin-bottom:0;
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border-bottom: 1px solid #fff;
        border-right: 1px solid #fff;
        background-color: $table-bg-accent;
        text-align: center;
        padding:10px 15px;
        vertical-align: middle;
        &:first-child {
          padding-left:0;
          background-color: #fff;
          text-align: left;
          border-right: 0;
          border-color:$table-border-color;
        }

        &:last-child {
          border-right: 0;
        }

        select, input {
          width:100%;
          @extend .form-control;

          //make it a bit smaller
          height:36px;
          border-color:$table-border-color;
          background-color: #fff;
        }

        select {
          padding-left:0;
          padding-right:0;
          font-size:inherit;
        }

        input {
          text-align: center;
        }

        .table-calculator-input-euro {
          position:relative;
          &:after {
            content:'\20AC'; //euro sign
            display:inline-block;
            position:absolute;
            top:6px;
            left:9px;
            pointer-events: none;
            color: $input-color;
            font-size:16px;
          }
        }

      }

      //last tr
      &:last-child {
        > th, > td {
          border-bottom:0;
        }
      }

    }
  }

  tfoot {
    > tr {
      > th,
      > td {
          border-top:0;
      }
    }
  }

  > thead:first-child > tr:first-child > th {
    border-bottom: 1px solid #fff;
    &:first-child {
      border-color:$table-border-color;
    }

  }

  > tbody,
  > tfoot {
    > tr {
      > th {
        font-weight:normal;
        .tooltip {
          position:relative;
          float:right;
        }
      }
      > td {
        .errortooltip {
          position:absolute;
          float:none;
          top:14px;
          right:22px;
        }
      }
    }
  }
  > thead {
    > tr {
      > th {
        text-align: center;
      }
    }
  }

  > tfoot {
    > tr {
      > th,
      > td {
        background-color: #fff;
        text-align: left;
        padding-left:0;
      }
    }
  }

  //make table 2 columns wide on xs screen
  &.table-calculator-xs-wrap {
    @include isaac-respond-max-width(xs) {
      display:block;
      width:100%;
      border:1px solid $table-border-color;

      tbody, thead, tfoot {
        display:block;
      }

      tr {
        display:block;
        position:relative;
        overflow:hidden;
      }

      th, td {
        display:block;
        float:left;
      }

      > thead > tr {
        > th:first-child {
          display:none;
        }
        > th {
          width:50%;
          min-height:65px;  //add height to make sure th is two lines high
        }
      }

      > tbody > tr {
        > th, td {
          width:50%;
          background-color: $table-bg-accent;
          line-height:36px; //this vertical aligns the text
          min-height:57px;  //add height when there is no content
          border-top:0;
          &:first-child {
            width:100%;
            text-align: center;
            background-color: #fff;
            border:0;
          }
        }

        &:last-child {
          > th, td {
            background-color: transparent;
          }
        }

      }

      > tfoot > tr {
        > td:first-child {
          display:none;
        }
        > td {
          width:100%;
          border:0;
          text-align: center;
        }
      }

    }
  }

}

.table-minimal {
  > thead > tr,
  > tbody > tr,
  > tfoot > tr  {

    td, th {
      border:0;
      &:first-child {
        padding-left:0;
      }

      &:last-child {
        padding-right:0;
      }
    }

  }
}

.table-small {
  > thead > tr,
  > tbody > tr,
  > tfoot > tr  {
    
    td, th {
      font-size: 13px;
      padding-left:2px;
      padding-right:2px;
    }
    
    th {
      line-height: 0.5;
    }
  }
}
