header {

  $sticky-header-height-xs: 77px;
  $sticky-header-height-sm: 102px;

  #header-sticky {
    border-bottom:1px solid $brand-primary;
    position:static; //.affix is added by affix plugin, but should be ignored on for smaller viewports
    height: $sticky-header-height-xs;
    @include isaac-respond-min-width(sm) {
      height: $sticky-header-height-sm;
    }

  }

  @include isaac-respond-min-width(md) {
    //reserve space for the sticky header and position the header on top of it
    margin-bottom: $sticky-header-height-sm;
    #header-sticky {
      position:absolute;
      width: 100%;
      z-index: $zindex-dropdown - 1;
      background-color: #fff;
      //.affix class is added by the Bootstrap affix plugin
      &.affix {
        position:fixed;
        top:0;
      }
    }
  }

  /*
  .sticky-header & {
    margin-top: $sticky-header-height;

    #header-sticky {
      //disable affix on mobile
      position:static;

      @include isaac-respond-min-width(md) {
        position:fixed;
        width: 100%;
        top: 0;
        z-index: $zindex-dropdown - 1;
        background-color: #fff;
      }

    }
  }
  */

  .offcanvas-toggle {
    margin:18px 9px 0 0;

    @include isaac-respond-min-width(sm) {
      margin:32px 9px 0 0;
    }

    .offcanvas-toggle-bars {
      top:-1px;
      // Bars
      .bar {
        display: block;
        width: 19px;
        height: 3px;
        background-color:#fff;
      }
      .bar + .bar {
        margin-top: 3px;
      }
    }

    .offcanvas-toggle-label {
      padding-left:4px;
    }

  }

  //hide offcanvas toggle and sho close offcanvas button on sm only when offcanvas is open
  .btn-offcanvas-close {
    display: none;
  }

  @include isaac-respond-width(xs) {
    .offcanvas-open & {
      .offcanvas-toggle {
        display: none;
      }
      .offcanvas-toggle.btn-offcanvas-close {
        display: block;
      }
    }
  }

  .logo {
    margin:13px 0 0 0;

    //specify image width only, so it is known before the image is loaded
    //image height is auto
    > a > img {
      width:136px;
    }

    @include isaac-respond-min-width(sm) {
      margin:21px 25px 0 0;
      > a > img {
        width:166px;
      }
    }

    @include isaac-respond-min-width(md) {
      margin:-13px 25px 0 0;
    }

  }


  #searchbar {
    position:absolute;
    width:100%;
    background-color:$brand-tertiary;
    z-index:$zindex-dropdown - 1;
    padding:14px 0;
  }
}

.afm {
  background: url(img/afm.svg) no-repeat center center;
  background-size: 380px;
  text-indent: -99999px;
  height: 100px;
  border-bottom: 1px solid #000;
  @include isaac-respond-width(xs) {
    background-size: 260px;
    height: 70px;
  }
}