.rating {
  position:relative;
  display:inline-block;

  span {
    display:none;
  }

  &:after,
  &:before {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1;
    font-size:27px;

    @include isaac-respond-min-width(sm) {
      font-size:20px;
    }

    @include isaac-respond-min-width(md) {
      font-size:27px;
    }

  }

  //5 background color stars
  &:before {
    display:inline-block;
    //note: \00a0 = non breaking space,  \e608 - star (icon)
    content: "\e608\00a0\e608\00a0\e608\00a0\e608\00a0\e608";
    color:$brand-spotcolor-dark;
  }

  &:after {
    display:inline-block;
    position:absolute;
    color:#009fe3;
    left:0;
    top:0;
  }

  //make sure to add at least one &nbsp; to get perfect alignment for IE11
  &.rating-0-5:after {
    content: "\e607\00a0";
  }

  &.rating-1:after {
    content: "\e608\00a0";
  }

  &.rating-1-5:after {
    content: "\e608\00a0\e607";
  }

  &.rating-2:after {
    content: "\e608\00a0\e608";
  }

  &.rating-2-5:after {
    content: "\e608\00a0\e608\00a0\e607";
  }

  &.rating-3:after {
    content: "\e608\00a0\e608\00a0\e608";
  }

  &.rating-3-5:after {
    content: "\e608\00a0\e608\00a0\e608\00a0\e607";
  }

  &.rating-4:after {
    content: "\e608\00a0\e608\00a0\e608\00a0\e608";
  }

  &.rating-4-5:after {
    content: "\e608\00a0\e608\00a0\e608\00a0\e608\00a0\e607";
  }

  &.rating-5:after {
    content: "\e608\00a0\e608\00a0\e608\00a0\e608\00a0\e608";
  }

}


.list-ratings {
  @include list-unstyled;
  > li {
    padding:20px 0 10px;
    border-top:1px solid $gray-lighter;

    .rating-author {
      font-size:16px;
    }

    .rating-metadata {
      color:$gray-light;
      margin:9px 0 8px;
    }

    .rating-pluses {
      label {
        font-weight:bold;
      }
    }

  }


}

.rating-label {
  color:$brand-primary;
  font-family: $headings-font-family;
  font-weight: bold;
  font-style: italic;
  padding-top:5px;
    p {
        float: left;
        margin-top:20px;
    }

    .rating-value {
        font-size: 40px;
        display: inline-block;
        margin:0 0 9px 10px;
        float:left;
    }
}