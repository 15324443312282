.tipsy {
  padding: 5px;
  font-size: 10px;
  background-repeat: no-repeat;
  background-image: url(img/tipsy.gif);
}

.tipsy-inner, .errortip-inner {
  padding: 10px 15px 15px;
  background-color: #e2f4f8;
  color: #203061;
  max-width: 250px;
  text-align: left;
  font-size:15px;
  -moz-box-shadow: 3px 2px 2px #c2c2c2; /* FF3.5+ */
  -webkit-box-shadow: 3px 2px 2px #c2c2c2; /* Saf3.0+, Chrome */
  box-shadow: 3px 2px 2px #c2c2c2; /* Opera 10.5, IE9, Chrome 10+ */
  z-index:999;
}
.tipsy-west, .errortip-west { background-position: left center; }

.errortip-inner {
  color: red !important;
}

.tooltip,
.tipsy-tooltip {
  margin:0;
  padding:0;
  cursor:default;
  text-decoration: none!important; //for IE
  &:after {
    display:inline-block;
    border:1px solid $brand-tertiary;
    color:$brand-tertiary;
    text-align: center;
    line-height:21px;
    width:21px;
    height:21px;
    border-radius:50%;
    font-size:15px;
    font-weight:bold;
    line-height:1;
    padding-top:2px;
    content: "i";
  }
}

.validicon {
  &:before {
    font-family: 'icomoon';
    font-size:20px;
    display:block;
    @extend .icon-check;
    color:#00c70e;
  }
}


.errortooltip,
.error.tipsy {
  background-image: none;
  padding:0;
  text-decoration: none;
  &:before {
    font-family: 'icomoon';
    font-size:20px;
    display:block;
    @extend .icon-exclamation-triangle;
    color:$brand-secondary;
  }
  display:none;
  float:right;
}