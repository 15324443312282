/**
 * @license
 * MyFonts Webfont Build ID 3002304, 2015-04-02T04:06:36-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Nevo-Regular by Meat Studio
 * URL: http://www.myfonts.com/fonts/meat-studio/nevo/regular/
 *
 * Webfont: Nevo-UltraBold by Meat Studio
 * URL: http://www.myfonts.com/fonts/meat-studio/nevo/ultra-bold/
 *
 * Webfont: Nevo-Medium by Meat Studio
 * URL: http://www.myfonts.com/fonts/meat-studio/nevo/medium/
 *
 * Webfont: Nevo-Thin by Meat Studio
 * URL: http://www.myfonts.com/fonts/meat-studio/nevo/thin/
 *
 * Webfont: Nevo-Bold by Meat Studio
 * URL: http://www.myfonts.com/fonts/meat-studio/nevo/bold/
 *
 * Webfont: Nevo-SemiBold by Meat Studio
 * URL: http://www.myfonts.com/fonts/meat-studio/nevo/semi-bold/
 *
 * Webfont: Nevo-ThinItalic by Meat Studio
 * URL: http://www.myfonts.com/fonts/meat-studio/nevo/thin-italic/
 *
 * Webfont: Nevo-SemiBoldItalic by Meat Studio
 * URL: http://www.myfonts.com/fonts/meat-studio/nevo/semi-bold-italic/
 *
 * Webfont: Nevo-UltraBoldItalic by Meat Studio
 * URL: http://www.myfonts.com/fonts/meat-studio/nevo/ultra-bold-italic/
 *
 * Webfont: Nevo-BoldItalic by Meat Studio
 * URL: http://www.myfonts.com/fonts/meat-studio/nevo/bold-italic/
 *
 * Webfont: Nevo-RegularItalic by Meat Studio
 * URL: http://www.myfonts.com/fonts/meat-studio/nevo/regular-italic/
 *
 * Webfont: Nevo-Light by Meat Studio
 * URL: http://www.myfonts.com/fonts/meat-studio/nevo/light/
 *
 * Webfont: Nevo-MediumItalic by Meat Studio
 * URL: http://www.myfonts.com/fonts/meat-studio/nevo/medium-italic/
 *
 * Webfont: Nevo-LightItalic by Meat Studio
 * URL: http://www.myfonts.com/fonts/meat-studio/nevo/light-italic/
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3002304
 * Licensed pageviews: 10,000
 * Webfonts copyright: Copyright &#x00A9; 2014 by Stew Deane. All rights reserved.
 *
 * © 2015 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/2dcfc0");


@font-face {
  font-family: 'Nevo';
  src: url('/global/fonts/nevo/2DCFC0_0_0.eot');src: url('/global/fonts/nevo/2DCFC0_0_0.eot?#iefix') format('embedded-opentype'),url('/global/fonts/nevo/2DCFC0_0_0.woff2') format('woff2'),url('/global/fonts/nevo/2DCFC0_0_0.woff') format('woff'),url('/global/fonts/nevo/2DCFC0_0_0.ttf') format('truetype');
}
@font-face {
  font-family: 'Nevo';
  src: url('/global/fonts/nevo/2DCFC0_4_0.eot');
  src: url('/global/fonts/nevo/2DCFC0_4_0.eot?#iefix') format('embedded-opentype'),url('/global/fonts/nevo/2DCFC0_4_0.woff2') format('woff2'),url('/global/fonts/nevo/2DCFC0_4_0.woff') format('woff'),url('/global/fonts/nevo/2DCFC0_4_0.ttf') format('truetype');
  font-weight: bold;
}
//@font-face {font-family: 'Nevo-UltraBold';src: url('/global/fonts/nevo/2DCFC0_1_0.eot');src: url('/global/fonts/nevo/2DCFC0_1_0.eot?#iefix') format('embedded-opentype'),url('/global/fonts/nevo/2DCFC0_1_0.woff2') format('woff2'),url('/global/fonts/nevo/2DCFC0_1_0.woff') format('woff'),url('/global/fonts/nevo/2DCFC0_1_0.ttf') format('truetype');}
//@font-face {font-family: 'Nevo-Medium';src: url('/global/fonts/nevo/2DCFC0_2_0.eot');src: url('/global/fonts/nevo/2DCFC0_2_0.eot?#iefix') format('embedded-opentype'),url('/global/fonts/nevo/2DCFC0_2_0.woff2') format('woff2'),url('/global/fonts/nevo/2DCFC0_2_0.woff') format('woff'),url('/global/fonts/nevo/2DCFC0_2_0.ttf') format('truetype');}
//@font-face {font-family: 'Nevo-Thin';src: url('/global/fonts/nevo/2DCFC0_3_0.eot');src: url('/global/fonts/nevo/2DCFC0_3_0.eot?#iefix') format('embedded-opentype'),url('/global/fonts/nevo/2DCFC0_3_0.woff2') format('woff2'),url('/global/fonts/nevo/2DCFC0_3_0.woff') format('woff'),url('/global/fonts/nevo/2DCFC0_3_0.ttf') format('truetype');}
//@font-face {font-family: 'Nevo-SemiBold';src: url('/global/fonts/nevo/2DCFC0_5_0.eot');src: url('/global/fonts/nevo/2DCFC0_5_0.eot?#iefix') format('embedded-opentype'),url('/global/fonts/nevo/2DCFC0_5_0.woff2') format('woff2'),url('/global/fonts/nevo/2DCFC0_5_0.woff') format('woff'),url('/global/fonts/nevo/2DCFC0_5_0.ttf') format('truetype');}
//@font-face {font-family: 'Nevo-ThinItalic';src: url('/global/fonts/nevo/2DCFC0_6_0.eot');src: url('/global/fonts/nevo/2DCFC0_6_0.eot?#iefix') format('embedded-opentype'),url('/global/fonts/nevo/2DCFC0_6_0.woff2') format('woff2'),url('/global/fonts/nevo/2DCFC0_6_0.woff') format('woff'),url('/global/fonts/nevo/2DCFC0_6_0.ttf') format('truetype');}
//@font-face {font-family: 'Nevo-SemiBoldItalic';src: url('/global/fonts/nevo/2DCFC0_7_0.eot');src: url('/global/fonts/nevo/2DCFC0_7_0.eot?#iefix') format('embedded-opentype'),url('/global/fonts/nevo/2DCFC0_7_0.woff2') format('woff2'),url('/global/fonts/nevo/2DCFC0_7_0.woff') format('woff'),url('/global/fonts/nevo/2DCFC0_7_0.ttf') format('truetype');}
//@font-face {font-family: 'Nevo-UltraBoldItalic';src: url('/global/fonts/nevo/2DCFC0_8_0.eot');src: url('/global/fonts/nevo/2DCFC0_8_0.eot?#iefix') format('embedded-opentype'),url('/global/fonts/nevo/2DCFC0_8_0.woff2') format('woff2'),url('/global/fonts/nevo/2DCFC0_8_0.woff') format('woff'),url('/global/fonts/nevo/2DCFC0_8_0.ttf') format('truetype');}
//@font-face {font-family: 'Nevo-BoldItalic';src: url('/global/fonts/nevo/2DCFC0_9_0.eot');src: url('/global/fonts/nevo/2DCFC0_9_0.eot?#iefix') format('embedded-opentype'),url('/global/fonts/nevo/2DCFC0_9_0.woff2') format('woff2'),url('/global/fonts/nevo/2DCFC0_9_0.woff') format('woff'),url('/global/fonts/nevo/2DCFC0_9_0.ttf') format('truetype');}
//@font-face {font-family: 'Nevo-RegularItalic';src: url('/global/fonts/nevo/2DCFC0_A_0.eot');src: url('/global/fonts/nevo/2DCFC0_A_0.eot?#iefix') format('embedded-opentype'),url('/global/fonts/nevo/2DCFC0_A_0.woff2') format('woff2'),url('/global/fonts/nevo/2DCFC0_A_0.woff') format('woff'),url('/global/fonts/nevo/2DCFC0_A_0.ttf') format('truetype');}
//@font-face {font-family: 'Nevo-Light';src: url('/global/fonts/nevo/2DCFC0_B_0.eot');src: url('/global/fonts/nevo/2DCFC0_B_0.eot?#iefix') format('embedded-opentype'),url('/global/fonts/nevo/2DCFC0_B_0.woff2') format('woff2'),url('/global/fonts/nevo/2DCFC0_B_0.woff') format('woff'),url('/global/fonts/nevo/2DCFC0_B_0.ttf') format('truetype');}
//@font-face {font-family: 'Nevo-MediumItalic';src: url('/global/fonts/nevo/2DCFC0_C_0.eot');src: url('/global/fonts/nevo/2DCFC0_C_0.eot?#iefix') format('embedded-opentype'),url('/global/fonts/nevo/2DCFC0_C_0.woff2') format('woff2'),url('/global/fonts/nevo/2DCFC0_C_0.woff') format('woff'),url('/global/fonts/nevo/2DCFC0_C_0.ttf') format('truetype');}
//@font-face {font-family: 'Nevo-LightItalic';src: url('/global/fonts/nevo/2DCFC0_D_0.eot');src: url('/global/fonts/nevo/2DCFC0_D_0.eot?#iefix') format('embedded-opentype'),url('/global/fonts/nevo/2DCFC0_D_0.woff2') format('woff2'),url('/global/fonts/nevo/2DCFC0_D_0.woff') format('woff'),url('/global/fonts/nevo/2DCFC0_D_0.ttf') format('truetype');}


@font-face {
  font-family: 'Open Sans';
  src: url('/global/fonts/opensans/OpenSans-Regular-webfont.eot');
  src: url('/global/fonts/opensans/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('/global/fonts/opensans/OpenSans-Regular-webfont.woff2') format('woff2'),
  url('/global/fonts/opensans/OpenSans-Regular-webfont.woff') format('woff'),
  url('/global/fonts/opensans/OpenSans-Regular-webfont.ttf') format('truetype'),
  url('/global/fonts/opensans/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: bold;
  src: url('/global/fonts/opensans/OpenSans-Semibold.eot');
  src: url('/global/fonts/opensans/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'),
  url('/global/fonts/opensans/OpenSans-Semibold.woff') format('woff'),
  url('/global/fonts/opensans/OpenSans-Semibold.ttf') format('truetype'),
  url('/global/fonts/opensans/OpenSans-Semibold.svg#OpenSans') format('svg');
}


//add subtle transition on elements that have a hovered or active state
a, .btn, .form-control {
  transition: color 0.2s ease-in-out 0s,
    background 0.2s ease-in-out 0s,
    border 0.2s ease-in-out 0s,
    text-decoration 0.2s ease-in-out 0s,
    box-shadow 0.2s ease-in-out 0s
  ;
}


.text-underline {
  text-decoration: underline;
}

.list-check {
  @include list-unstyled;
  > li {
    padding-left:30px;
    padding-bottom:8px;
    &:before {
      font-family: 'icomoon';
      font-size:20px;
      display:block;
      position:absolute;
      @extend .icon-check;
      color:#00c70e;
      margin-left:-30px;
      margin-top:-4px;
    }
  }
}

.list-cross {
  @include list-unstyled;
  > li {
    padding-left:30px;
    padding-bottom:8px;
    &:before {
      font-family: 'icomoon';
      font-size:20px;
      display:block;
      position:absolute;
      @extend .icon-cross;
      color:#f26722;
      margin-left:-30px;
      margin-top:-4px;
    }
  }
}

.link-more {
  &:after {
    font-family: 'icomoon';
    content: "\e60c";
    //position absolute to make sure the arrow doesn't get the underline hover style
    position:absolute;
    margin-left:5px;
    margin-top:2px;
  }
  &.btn:after {
    margin-top:-1px;
  }
}

.header-border-bottom {
  border-bottom:1px solid $gray-lighter;
}

h1.header-no-top-margin,
h2.header-no-top-margin,
h3.header-no-top-margin,
h4.header-no-top-margin,
h5.header-no-top-margin,
h6.header-no-top-margin {
  margin-top:0;
}



.section-header {
  @include isaac-respond-min-width(sm) {
    padding-bottom:10px;
  }

  h2 {
    text-align: center;
    color:$brand-primary;
    font-size: 24px;
    line-height:1.2;

    margin:22px 0 16px 0;
    @include isaac-respond-min-width(sm) {
      font-size: 36px;
      margin:40px 0 28px 0;
    }

    &:after {
      content: '';
      display: block;
      height:1px;
      background-color: $brand-primary;
      width: 380px;
      max-width:100%;
      position:relative;
      margin:22px auto 0 auto;
      .section-dark & {
        background-color: #fff;
      }
    }
  }


  .lead {
    margin-bottom: $line-height-computed;
    font-size: 18px;
    font-family: $headings-font-family;
    font-weight: bold;
    font-style: italic;
    line-height: 1.4;
    text-align: center;


    @include isaac-respond-min-width(sm) {
      font-size: 24px;
    }
  }
}

//default styling for content that was added by the Dotcms WYSIWYG editor
.content-wysiwyg {
  h3 {
    margin-top:40px;
    margin-bottom:8px;
  }

  ul {
    padding-left: 0;
    list-style: none;
    > li {
      position:relative;
      padding-left: 16px;
      padding-bottom:8px;
      &:before {
        font-family: 'icomoon';
        content: "\e60c"; //angle-right
        padding-right:10px;
        color:$brand-primary;
        position: absolute;
        top:1px;
        left:0;
      }
    }
  }
}